var moment = require('moment');

//
function beautify_time(timestamp){
    var mistiming = Math.round(new Date() / 1000)-moment(timestamp).unix();

    var postfix = mistiming>0 ? '前' : '后'
    mistiming = Math.abs(mistiming)
    var arrr = ['年','个月','星期','天','小时','分钟','秒'];
    var arrn = [31536000,2592000,604800,86400,3600,60,1];
    for(var i=0; i<7; i++){
        var inm = Math.floor(mistiming/arrn[i])
        if(inm!=0){
            return inm+arrr[i] + postfix
        }
    }
}


function get7Days(){    
                //获取系统当前时间
                var now = new Date(); 
                var nowTime = now.getTime() ; 
                var oneDayTime = 24*60*60*1000 ;
				var arry=[];
                for(var i = 0 ; i < 10 ; i++){ 
                    //显示周一
                    var ShowTime = nowTime + i*oneDayTime ; 
//                    var ShowTime = nowTime + (i+1)*oneDayTime ; 
                    //初始化日期时间
                    var myDate = new Date(ShowTime);
                    var year=myDate.getFullYear();    
                    var month=myDate.getMonth()+1;  
                    var date=myDate.getDate(); 
                    console.log(year+"-"+month+"-"+date)
                    var str = "星期" + "日一二三四五六".charAt(myDate.getDay());
                    console.log(str);
					
					
					if((month+"").length==1){
						month="0"+month;
					}
					var dateStr="";
					if((date+"").length==1){
						dateStr="0"+date;
					}else{
						dateStr=date
					}
					
					arry.push({
						day:date,
						dateY:year+"-"+month+"-"+dateStr,
						date:month+"-"+dateStr,
						week:str
					})
                }  
						  
						  return arry;
            }

/***时间相减 返回分钟
***/
function subtract(start,end){
	
	
	var m1 = moment(start),
	    m2 = moment(end);

	return m2.diff(m1, 'minute');
}

/**秒转分**/
function miaoTofen(value,type){
	if(!type){
		type='HH:mm:ss';
	}
	let time = moment.duration(value, 'seconds')  //得到一个对象，里面有对应的时分秒等时间对象值
	  let hours = time.hours() 
	  let minutes = time.minutes()
	  let seconds = time.seconds()
	return (moment({h:hours, m:minutes, s:seconds}).format(type))
	
}

/***获取当前时间
***/
function thisTime(type){
	if(!type){
		type='YYYY-MM-DD HH:mm:ss';
	}
	let now = moment().locale('zh-cn').format(type);
	
	return now;
}
function timeToStr(val,type){
	if(!type){
		type='YYYY-MM-DD HH:mm:ss';
	}
	let now = moment(val).locale('zh-cn').format(type);
	
	return now;
}
//当前时间 加减天 
function thisAddDate(value,type){
	if(!type){
		type='YYYY-MM-DD HH:mm:ss';
	}
	let now = moment().add(value, 'days').locale('zh-cn').format(type);
	
	return now;
}
/***
**time, 字符串时间
**value, 加减数 
**type 数据格式
**seconds 单位  seconds秒
* years	y
quarters	Q
months	M
weeks	w
days	d
hours	h
minutes	m
seconds	s
***/
function timeAdd (time,value,type,seconds){
	if(!type){
		type='YYYY-MM-DD HH:mm:ss';
	}
	let now = moment(time).add(value, seconds).locale('zh-cn').format(type);
	
	return now;
}

/**年相减 获得年龄
 * **/
function    getAge(a, b) {
                    a = new Date(a.replace(/-/g, '/'));
                    b = new Date(b.replace(/-/g, '/'));
                    var d = Math.abs(a.getTime() - b.getTime()) / 1000 / 24 / 60 / 60;
                    var year = Math.floor(d / 365);//不整除取最小的年数或者直接进位（Math.ceil），或者四舍五入Math.round，自己改这个罗
                    return year;
                }


export default {
	beautify_time,
	miaoTofen,
	thisAddDate,
	thisTime,
	timeAdd,
	getAge,
	subtract,
	get7Days,
	timeToStr
}